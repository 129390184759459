import type { RoleId } from 'types/auth'

export const WARTEK_USER_ROLE: RoleId = 'super-admin'

export const DEFAULT_ALLOWED_ROLES: RoleId[] = [WARTEK_USER_ROLE]

export const roleNames = {
  [WARTEK_USER_ROLE]: 'super-admin',
}

const roles = {
  WARTEK_USER_ROLE,
}

export default roles
