import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { useRoles } from 'utils/hooks/useRoles'

import { permissions } from 'configs/policies'
import roles from 'configs/roles'

type Role = typeof roles[keyof typeof roles]

export function usePermission() {
  const { userHasOneOfRoles } = useRoles()
  const router = useRouter()

  const basePath = `/${router.pathname.split('/')[1]}`

  const getRolesByBasePath = useCallback(() => {
    // Route Path defined on policy
    if (permissions?.[router.pathname]) {
      return (
        permissions?.[router.pathname]?.roles ?? permissions?.[router.pathname]
      )
    }

    // Base Path defined on policy
    if (permissions?.[basePath]) {
      return permissions?.[basePath]?.roles ?? permissions?.[basePath]
    }

    return []
  }, [basePath, router])

  const getRolesByActions = useCallback(
    (actions: string[] = []) => {
      let actionRoles = []

      Object.values(actions).forEach((action) => {
        actionRoles = [
          ...actionRoles,
          ...(permissions?.[router.pathname] || permissions?.[basePath])
            ?.actions?.[action],
        ]
      })

      // @ts-ignore
      return [...new Set(actionRoles)]
    },
    [basePath, router]
  )

  const currentPathPermissions = (actions = []) => {
    if (actions.length) {
      const actionsRoles = getRolesByActions(actions)
      return actionsRoles
    }

    return getRolesByBasePath()
  }

  const checkPermissions = useCallback(
    (permissions: string[] = []): boolean => {
      if (permissions.length === 0) {
        return true
      } else {
        const hasValidRoles = userHasOneOfRoles(Object.values(roles) as Role[])
        const hasModulePermissions = userHasOneOfRoles(permissions as Role[])

        return hasValidRoles && hasModulePermissions
      }
    },
    [userHasOneOfRoles]
  )

  return {
    checkPermissions,
    currentPathPermissions,
  }
}
