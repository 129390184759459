import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { useRouter } from 'next/router'
import type { Provider } from 'next-auth/providers'
import { signOut, useSession } from 'next-auth/react'
import React from 'react'
import type { Session } from 'types/auth'

import { useToast } from '@wartek-id/toast'

import { useAsync, useLocalStorage, usePermission } from 'utils/hooks'

import { baseUrl } from 'configs/api'
import { CAR_TOOL_SESSION_STORAGE_KEY } from 'configs/auth'
import { DEFAULT_ALLOWED_ROLES } from 'configs/roles'
import { NONLOGIN_PATHS } from 'configs/routes'

import { AuthContext } from './AuthContext'

function isProtectedRoutes(pathname: string): boolean {
  return !NONLOGIN_PATHS.includes(pathname)
}

async function fetchToken(idToken: string): Promise<Session> {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: `${baseUrl}/usermanagement/v1/authenticate`,
      method: 'POST',
      data: {
        googleToken: idToken,
      },
    }

    const { data: payload } = (await axios(
      requestConfig
    )) as AxiosResponse<Session>

    return payload
  } catch (error) {
    throw error
  }
}

export function PermissionGuard({ children }) {
  const router = useRouter()
  const { checkPermissions, currentPathPermissions } = usePermission()
  const permitted = checkPermissions(currentPathPermissions())
  if (permitted) {
    return children
  }
  router.replace({
    pathname: '/404',
  })
  return null
}

export function AuthGuard(props) {
  const router = useRouter()
  const protectedRoutes = isProtectedRoutes(router.pathname)
  const toast = useToast()
  const { data: session, status: sessionStatus } = useSession()
  const { data, status, error, run } = useAsync<Session, AxiosError>()

  const usrSessionStorage = useLocalStorage(CAR_TOOL_SESSION_STORAGE_KEY, null)
  const account: Provider | any = session
  const isSessionLoading = sessionStatus === 'loading'
  const isLoggingOut = React.useRef(false)
  const hasUser: boolean =
    !!usrSessionStorage.data && Object.keys(usrSessionStorage.data).length > 0

  const logoutFn = async () => {
    // removing the storage (using useLocalStorage hook)
    // will rerender the page.
    // make sure that there is no logout process before
    // proceeding
    if (!isLoggingOut.current) {
      isLoggingOut.current = true
      await invalidateToken()
      usrSessionStorage.remove()
      await signOut({ callbackUrl: '/login' })
    }
  }

  const invalidateToken = React.useCallback(async () => {
    const token = usrSessionStorage?.data?.credential?.token
    const path = '/usermanagement/v1/unauthorize'
    if (hasUser) {
      try {
        const { data } = (await axios({
          url: `${baseUrl}${path}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })) as AxiosResponse

        return data
      } catch (error) {
        console.warn(error)
        return Promise.resolve()
      }
    } else {
      return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUser, session])

  // get API key
  React.useEffect(() => {
    // make sure that we are not in the process of logging out
    // the user. otherwise, they won't be logged out and they
    // have to redo logging out again before properly logged out
    if (account && status === 'idle' && !isLoggingOut.current) {
      run(fetchToken(account.idToken))
    }
  }, [account, status, run])

  // save API key credentials into user session
  React.useEffect(() => {
    if (data) {
      usrSessionStorage.set({
        credential: data,
      })
    }
    // eslint-disable-next-line
  }, [data])

  // Handle request errors
  React.useEffect(() => {
    if (!!error) {
      // if error happens when client is accessing protected routes
      // pushback to login
      const protectedRoutes = isProtectedRoutes(router.pathname)
      if (protectedRoutes) {
        toast({
          message: error.message,
        })
        setTimeout(() => {
          logoutFn()
        }, 2000)
      }
    }
    // eslint-disable-next-line
  }, [error])

  // Prevent passing context if session is still not finished
  if (isSessionLoading) {
    return null
  }

  const context = {
    isUnauthorized: false,
    activeRole: null,
    usrSession: null,
    logout: null,
  }
  const currentSession = usrSessionStorage.data

  // add context if api credential exists
  if (currentSession?.credential) {
    const role = currentSession.credential.roles.filter((role) =>
      DEFAULT_ALLOWED_ROLES.includes(role.name)
    )
    // if user role is not in any allowed roles, don't add context
    if (role.length) {
      context.activeRole = {
        name: role.name,
        value: role.id,
      }
      context.usrSession = currentSession.credential
      context.logout = logoutFn
    } else {
      // cleanup session if user doesn't have allowed roles
      usrSessionStorage.remove()
    }
  }

  // if client accessing protected routes
  if (protectedRoutes) {
    // if api credential exists
    if (currentSession?.credential) {
      // allow access
      return (
        <AuthContext.Provider value={context} {...props}>
          <PermissionGuard {...props} />
        </AuthContext.Provider>
      )
    }
    // pushback to login if user doesn't have credential
    logoutFn()

    // at this point, we know the user doesn't have any credential
    // to enter the protected routes. better not rendering anything
    // while waiting to be redirected to login page
    return null
  }

  // if client already log in into google but not getting api credential
  // set to unauthorized
  if (account && !currentSession?.credential) {
    context.isUnauthorized = true
  }

  // Pass context into unprotected routes
  return <AuthContext.Provider value={context} {...props} />
}
