import Router from 'next/router'
import { SessionProvider as NextAuthProvider } from 'next-auth/react'
import NProgress from 'nprogress'
import React from 'react'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { historyUtils } from '@wartek-id/fe-toolbox'
import { ToastProvider } from '@wartek-id/toast'

import { AuthGuard } from 'utils/hooks/useAuth'

import '@wartek-id/design-tokens/build/guru-desktop/css/color.css'
import '@wartek-id/design-tokens/build/guru-desktop/css/font.css'
import '@wartek-id/design-tokens/build/guru-desktop/css/size.css'
import '@wartek-id/design-tokens/build/guru-desktop/css/tokens.css'
import 'styles/global.css'
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'

Router.events.on('routeChangeStart', () => {
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  require('configs/mocks')
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  require('configs/mocks')
}

const App = ({ Component, pageProps, router }) => {
  return <Component {...pageProps} key={router.route} />
}

const AppProvider = ({
  Component,
  pageProps: { session, ...pageProps },
  router,
}) => {
  React.useEffect(() => {
    historyUtils.storeAppInitialPath()
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }
  }, [])

  return (
    <ToastProvider autoDismiss placement="top-center">
      <QueryClientProvider client={queryClient}>
        <NextAuthProvider session={session}>
          <AuthGuard {...pageProps}>
            <App Component={Component} pageProps={pageProps} router={router} />
          </AuthGuard>
        </NextAuthProvider>
      </QueryClientProvider>
    </ToastProvider>
  )
}

export default AppProvider
