import { CAR_TOOL_UI_STATE_STORAGE_KEY } from 'configs/auth'

import { useLocalStorage } from './useLocalStorage'

interface UIState {
  homePanelCollapsed?: boolean
}

export const initialUIstate: UIState = {
  homePanelCollapsed: false,
}

export const useUIState = (): {
  data: UIState
  setUIState: (state: UIState) => void
  removeUIState: () => void
} => {
  const { data, set, remove } = useLocalStorage(
    CAR_TOOL_UI_STATE_STORAGE_KEY,
    initialUIstate
  )

  return {
    data,
    setUIState: (state: UIState) => set({ ...data, ...state }),
    removeUIState: remove,
  }
}
