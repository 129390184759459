import React from 'react'

import { useAuth } from 'utils/hooks/useAuth'

import availableRoles from 'configs/roles'

type Role = typeof availableRoles[keyof typeof availableRoles]

export const useRoles = () => {
  const { usrSession } = useAuth()
  const currentRoles: string[] = React.useMemo(() => {
    return !!usrSession ? usrSession.roles.map((group) => group.name) : []
  }, [usrSession])

  const userHasRole = React.useCallback(
    (role: Role): boolean => currentRoles.includes(role),
    [currentRoles]
  )

  const userHasOneOfRoles = React.useCallback(
    (roles: Role[]) => roles.some((role) => currentRoles.includes(role)),
    [currentRoles]
  )

  return {
    userHasRole,
    userHasOneOfRoles,
    userRoles: currentRoles,
  }
}
