import React from 'react'
import type { AuthContextProps } from 'types/auth'

export const AuthContextDefaultValue: AuthContextProps = {
  isUnauthorized: false,
  activeRole: null,
  usrSession: null,
  logout: () => ({}),
}

export const AuthContext = React.createContext<AuthContextProps>(
  AuthContextDefaultValue
)

AuthContext.displayName = 'AuthContext'
