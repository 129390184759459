import { DEFAULT_ALLOWED_ROLES } from './roles'
/*
{
  '/': [], // set empty to allow all access to all roles,
  '/simulasi-mengajar': [roles.ASESOR], // only user with asesor role can open this page
  '/wawancara': {
    roles: [roles.ASESOR] // this will result the same thing with above
  },
  '/profile': {
    roles: [roles.PGP, roles.ASESOR],
    actions: { // this will be a reference for component which need checking to certain role
      create: [roles.PGP, roles.ASESOR],
      delete: [roles.PGP],
    },
  }
}
*/

export const permissions = {
  '/home': DEFAULT_ALLOWED_ROLES,
  // '/textcontent': BCKS_ADMIN_ROLES,
  '/textcontent': DEFAULT_ALLOWED_ROLES,
  '/builder': DEFAULT_ALLOWED_ROLES,
  '/builder-v2': DEFAULT_ALLOWED_ROLES,
}
