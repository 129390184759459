export const NONLOGIN_PATHS = [
  '/login',
  '/logout',
  '/error',
  '/404',
  '/',
  '/playground',
  '/builder-preview',
  '/builder-data-store-preview',
  '/builder-ssr-preview',
  '/builder/preview',
  '/site/[pageKey]',
]
